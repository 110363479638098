// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack, TextField, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { OfferProps } from 'src/@types/offer';

// ----------------------------------------------------------------------

const encode = (data: any) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

// ----------------------------------------------------------------------


export default function OfferForm({ offer }: { offer: OfferProps }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm();
  const { slug } = offer;
  const router = useRouter();

  const onSubmit = (data: { [x: string]: any; }) => {
    fetch('https://formspree.io/f/xpzbqezp', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: encode({ 'form-name': 'contact', 'offer': router.query.slug, ...data }),
    })
      .then(() => {

        // Push form event to google analytics datalayer
        //@ts-ignore
        window.dataLayer = window.dataLayer || [];
        //@ts-ignore
        window.dataLayer.push({ event: 'offer_submit', offer: slug.current });
      })
      .then(() => router.push('/thank-you'))
      .catch((error) => alert(error));
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'h4' }}>
            Kontakt oss
          </Stack>

          <Stack spacing={1.5}>
            <TextField
              error={!!errors.name}
              fullWidth
              label="Navn*"
              {...register('name', { required: true })}
            />
            <TextField
              error={!!errors.phone}
              fullWidth
              label="Telefonnummer*"
              {...register('phone', { required: true })}
            />
            <TextField
              error={!!errors.email}
              fullWidth
              label="Email*"
              {...register('email', { required: true })}
            />
          </Stack>
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            Henvendelsen er 100% uforpliktende. Vi tar kontakt med deg omgående.
          </Typography>
          <LoadingButton
            loading={isSubmitSuccessful && isValid}
            type="submit"
            size="large"
            color="success"
            variant="contained"
          >
            Send henvendelse
          </LoadingButton>
        </Stack>
      </form>
      <form name="offer" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="offer" />
        <input type="hidden" name="offer" />
        <input type="hidden" name="name" />
        <input type="hidden" name="phone" />
        <input type="hidden" name="email" />
      </form>
    </Card>
  );
}
