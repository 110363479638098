// icons
// import timeIcon from '@iconify/icons-carbon/time';
// @mui
import { Card, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { m } from 'framer-motion';
// next
import NextLink from 'next/link';
import { OfferProps } from 'src/@types/offer';
import { useResponsive } from 'src/hooks';
import { fPrice, fUnit } from 'src/utils/formatPrice';
// components
import { FabButtonAnimate, TextMaxLine } from '../../components';
import { varHover, varTranHover } from '../../components/animate';
import Image from '../../components/NextSanityImage';
// routes
import Routes from '../../routes';

// ----------------------------------------------------------------------

type Props = {
  offer: OfferProps;
  large?: boolean;
};

export default function OfferItem({ offer, large }: Props) {
  const { title, price, unit, description, slug, header, color } = offer;

  const isMobile = useResponsive("down", "md");

  return (
    <Card component={m.div} whileHover="hover">
      <Stack direction={large && !isMobile ? "row" : "column"} spacing={large && !isMobile ? 2 : 0}>

        <NextLink href={Routes.offer('[slug]')} as={Routes.offer(slug.current)}>
          <a>
            <Box bgcolor={color.value} sx={{ overflow: 'hidden' }}>
              <m.div variants={varHover(1.1)} transition={varTranHover()}>
                <Image alt={slug.current} src={header} ratio="16/9" height={isMobile ? 200 : 300} width={600} layout="intrinsic" />
              </m.div>
            </Box>
          </a>
        </NextLink>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Stack sx={{ p: 2.5 }}>
            <NextLink href={Routes.offer('[slug]')} as={Routes.offer(slug.current)} passHref>
              <TextMaxLine variant="h4" asLink persistent paragraph>
                {title}
              </TextMaxLine>
            </NextLink>
            <TextMaxLine variant="body2" sx={{ color: 'text.secondary' }}>
              {description}
            </TextMaxLine>
          </Stack>
          <div>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2.5 }}>
              <Stack direction="row" alignItems="end" spacing={1}>
                {price && (
                  <>
                    <Typography variant="h4" sx={{ lineHeight: 1.1 }}>
                      Kr {fPrice(price)}
                    </Typography>
                    {unit && (
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {fUnit(unit)}
                      </Typography>
                    )}
                  </>
                )}
              </Stack>

              {/* <TextIconLabel
          value={date}
          sx={{
            typography: 'body3',
            color: 'text.disabled',
          }}
        /> */}
              <NextLink href={Routes.offer('[slug]')} as={Routes.offer(slug.current)} passHref>
                <FabButtonAnimate variant="extended" color="primary">
                  Se mer
                </FabButtonAnimate>
              </NextLink>
            </Stack>
          </div>
        </Box>

      </Stack>

    </Card>
  );
}
