import { Box } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { Image as ImageProps } from '@sanity/types';
import imageUrlFor from '@utils/image';
import { m } from 'framer-motion';
import { useState } from 'react';
// lightbox
import 'react-image-lightbox/style.css';
import { useResponsive } from 'src/hooks';
import { A11y, FreeMode } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
// components
import { LightboxModal } from '../../components';
import { varTranHover } from '../../components/animate';
import Image from '../../components/NextSanityImage';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  gallery: {
    images: ImageProps[];
  };
};

export default function OfferGallery({ gallery }: Props) {
  const { images } = gallery;
  const isDesktop = useResponsive('up', 'md');
  const imageUrls = Array(images.length);
  images.forEach((item, i) => {
    imageUrls[i] = imageUrlFor(item).url();
  });

  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const handleOpenLightbox = (url: string) => {
    const selectedImage = imageUrls.findIndex((index) => url === index);

    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <RootStyle>
      {isDesktop ? (
        <><Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          {images?.length > 0 && (
            <PhotoItem photo={images[0]} onOpenLightbox={() => handleOpenLightbox(imageUrls[0])} />
          )}
          {(images?.length > 1 && isDesktop) && (
            <PhotoItem photo={images[1]} onOpenLightbox={() => handleOpenLightbox(imageUrls[0])} />
          )}
          <Box
            sx={{
              display: 'grid',
              gap: 1,
              gridTemplateColumns: 'repeat(2, 2fr)',
            }}
          >
            {isDesktop
              ? images
                ?.slice(2, 5)
                .map((photo: ImageProps, i: number) => (
                  <PhotoItem
                    key={imageUrls[i]}
                    photo={photo}
                    onOpenLightbox={() => handleOpenLightbox(imageUrls[i])}
                  />
                ))
              : images
                ?.slice(1, 3)
                .map((photo: ImageProps, i: number) => (
                  <PhotoItem
                    key={imageUrls[i]}
                    photo={photo}
                    onOpenLightbox={() => handleOpenLightbox(imageUrls[i])}
                  />
                ))}
          </Box>
        </Box>

          <LightboxModal
            images={imageUrls}
            mainSrc={imageUrls[selectedImage]}
            photoIndex={selectedImage}
            setPhotoIndex={setSelectedImage}
            isOpen={openLightbox}
            onCloseRequest={() => setOpenLightbox(false)}
          />
        </>
      ) : (
        <Swiper
          modules={[A11y, FreeMode]}
          speed={1000}
          spaceBetween={10}
          slidesPerView={1.5}
        >
          {images.map((photo: ImageProps, i: number) => (
            <SwiperSlide key={photo.asset._ref}>
              <PhotoItem
                photo={photo}
                onOpenLightbox={() => handleOpenLightbox(imageUrls[i])}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type PhotoItemProps = {
  photo: ImageProps;
  onOpenLightbox: VoidFunction;
};

function PhotoItem({ photo, onOpenLightbox }: PhotoItemProps) {
  return (
    <m.div
      whileHover="hover"
      variants={{
        hover: { opacity: 0.8 },
      }}
      transition={varTranHover()}
    >
      <Image
        alt="photo"
        src={photo}
        ratio="1/1"
        onClick={onOpenLightbox}
        sx={{ borderRadius: 2, cursor: 'pointer' }}
      />
    </m.div>
  );
}
