// icons
import email from '@iconify/icons-carbon/email';
import phone from '@iconify/icons-carbon/phone';
// @mui
import { Avatar, Divider, Link, Stack, Typography } from '@mui/material';
import { imageProps } from '@utils/image';
import { OfferProps } from 'src/@types/offer';
import { useResponsive } from 'src/hooks';
// components
import { Iconify, TextIconLabel } from '../../components';

// ----------------------------------------------------------------------

type Props = {
  offer: OfferProps;
};

export default function OfferHeader({ offer }: Props) {
  const { title, contact, description } = offer;
  const isDesktop = useResponsive('up', 'sm');

  return (
    <>
      <Stack
        spacing={1}
        sx={{
          mb: { xs: 3, md: 3 },
        }}
      >
        <Typography variant="overline" sx={{ color: "text.secondary" }}>LAV PRIS</Typography>
        <Typography variant="h3" component="h1" sx={{ flexGrow: 1, pr: { md: 10 } }}>
          {title}
        </Typography>
      </Stack>
      <Typography sx={{ mb: 4 }}>{description}</Typography>
      <Stack
        spacing={{ xs: 2.5, sm: 1.5 }}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        divider={isDesktop && <Divider orientation="vertical" sx={{ height: 20, my: 'auto' }} />}
      >
        <TextIconLabel
          icon={
            <Avatar sx={{ width: 60, height: 60 }} src={imageProps(contact.profile)?.src} />
          }
          value={
            <>
              <Typography variant="subtitle2" sx={{ ml: 2, mr: 0.5 }}>
                {contact.name}
              </Typography>
            </>
          }
        />
        <Stack
          direction="row"
          spacing={1.5}
          divider={<Divider orientation="vertical" sx={{ height: 20, my: 'auto' }} />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Iconify icon={phone} sx={{ width: 22, height: 22 }} />
            <Link href={'tel:' + contact.phone} color="inherit" underline="none" variant="subtitle2">
              {contact.phone}
            </Link>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Iconify icon={email} sx={{ width: 22, height: 22 }} />
            <Link
              href={'mailto:' + contact.email}
              color="inherit"
              underline="none"
              variant="subtitle2"
            >
              {contact.email}
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
