// icons
import directionStraightRight from '@iconify/icons-carbon/direction-straight-right';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
// next
import NextLink from 'next/link';
import { OfferProps } from 'src/@types/offer';
import OfferItem from 'src/sections/offers/OfferItem';
// components
import { Iconify } from '../../components';
// routes
import Routes from '../../routes';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  offers: OfferProps[];
};

export default function OfferSimilar({ offers }: Props) {
  return (
    <RootStyle>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ mb: { xs: 8, md: 6 } }}
        >
          <Typography variant="h3">Flere kampanjer</Typography>

          <NextLink href={Routes.offers} passHref>
            <Button
              endIcon={<Iconify icon={directionStraightRight} sx={{ width: 22, height: 22 }} />}
              sx={{
                display: { xs: 'none', md: 'inline-flex' },
              }}
            >
              Se alle
            </Button>
          </NextLink>
        </Stack>

        <Box
          sx={{
            display: 'grid',
            gap: { xs: 4, md: 3 },
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          {offers.map((offer) => (
            <OfferItem key={offer._id} offer={offer} />
          ))}
        </Box>

        <Stack
          alignItems="center"
          sx={{
            mt: 8,
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <NextLink href={Routes.offers} passHref>
            <Button
              endIcon={<Iconify icon={directionStraightRight} sx={{ width: 22, height: 22 }} />}
            >
              Se alle
            </Button>
          </NextLink>
        </Stack>
      </Container>
    </RootStyle>
  );
}
