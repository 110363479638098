// @mui
import { Stack, Typography } from '@mui/material';
import PostBody from '@utils/PostBody';
import { OfferProps } from 'src/@types/offer';

// ----------------------------------------------------------------------

type Props = {
  offer: OfferProps;
};

export default function OfferDetails({ offer }: Props) {
  const { content } = offer;

  return (
    <Stack spacing={5}>

      <section>
        <Typography variant="h4" paragraph>
          Beskrivelse
        </Typography>
        <PostBody content={content} />
      </section>
    </Stack>
  );
}